import React from "react"
import PatternImg from "../../svg/blocks.svg"

function BackgroundPattern({ isTop = true }) {
   const position = isTop ? "top" : "bottom"
   return (
      <div
         className={`absolute -z-50 w-full h-full ${position}-0 left-1/2 max-w-[2000px]
                     transform -translate-x-1/2 md:h-[40rem] overflow-hidden
                     hidden md:block opacity-[90%]`}
      >
         <span
            className={`absolute bottom-0 h-[100%] ${
               isTop ? "bg-gradient-to-t" : "bg-gradient-to-b"
            } from-stone-200 via-stone-200/50 to-transparent inset-0`}
         ></span>
         <PatternImg />
      </div>
   )
}

export default BackgroundPattern
