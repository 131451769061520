import React from "react"
import PropTypes from "prop-types"
import ContainerSite from "../3_layouts/ContainerSite"
import Navigation from "../2_sections/Navigation"
import Footer from "../2_sections/Footer"
import TransitionPageIn from "../3_layouts/TransitionPageIn"
import BackgroundPattern from "../5_assets/motion/BackgroundPattern.js"

function Layout({ children, location }) {
   const allowedPaths = ["/", "/404/"]
   const shouldRenderBackgroundPattern = allowedPaths.includes(location.pathname)

   return (
      <div className="relative h-full w-full">
         <Navigation />
         <TransitionPageIn>
            <ContainerSite>
               {shouldRenderBackgroundPattern && <BackgroundPattern />}
               <main>{children}</main>
            </ContainerSite>
         </TransitionPageIn>
         <Footer />
      </div>
   )
}

Layout.propTypes = {
   children: PropTypes.node.isRequired,
   location: PropTypes.object.isRequired,
}

export default Layout
