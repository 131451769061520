import React, { useState, useEffect } from "react"
import IconArrow from "../svg/icons/arrow-curve-up.svg"

const ScrollToTop = ({ showBelow = 200, label = "" }) => {
   const [show, setShow] = useState(showBelow !== 0)

   const handleScroll = () => {
      if (window.pageYOffset > showBelow) {
         if (!show) setShow(true)
      } else {
         if (show) setShow(false)
      }
   }

   useEffect(() => {
      if (showBelow) {
         window.addEventListener("scroll", handleScroll)
         return () => window.removeEventListener("scroll", handleScroll)
      }
   }, [showBelow]) // Add showBelow as a dependency to useEffect

   const handleClick = () => {
      window.scrollTo({ top: 0, behavior: "smooth" }) // Added smooth scrolling behavior
   }

   return (
      <div className="inline-block">
         <button
            aria-label="Back to top"
            title="Back to top of page"
            className="bg-transparent ease-in-out transform hover:-translate-y-1 transition duration-200 focus:outline-none inline-block mx-auto md:mx-2 border border-secondary bg-white hover:border-primary p-2.5 rounded-full dark-text-hover shadow-hover"
            onClick={handleClick}
         >
            <IconArrow className="w-8 rotate-360" alt="" />
            <span className="sr-only">Back to top</span>
         </button>
         {label && <p className="body-sm space-top-xxs">{label}</p>}
      </div>
   )
}

export default ScrollToTop
