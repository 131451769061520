import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { useSpring, animated } from "react-spring"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import ContainerSite from "../3_layouts/ContainerSite"
import Button from "../1_components/Button"
import IconMenu from "../svg/icons/menu.svg"
import IconClose from "../svg/icons/close.svg"
import IconEnvelope from "../svg/icons/envelope-simple.svg"
import IconEyeClosed from "../svg/icons/eye-closed.svg"
import IconEyeOpen from "../svg/icons/eye-open.svg"

const IconComponent = () => {
   return (
      <div className="block w-10 md:w-11 p-1.5 rounded-full border border-dark">
         <span className="block group-hover:hidden">
            <IconEyeClosed />
         </span>
         <span className="hidden group-hover:block">
            <IconEyeOpen />
         </span>
      </div>
   )
}

const NavItems = ({ navItems }) => {
   return (
      <ul className="w-full hidden md:flex justify-between items-center">
         {navItems.map((item, i) => (
            <li key={i}>
               <AnchorLink to={item.to} title={item.text}>
                  <p className="font-bodybold text-xs uppercase dark-text-hover">{item.text}</p>
               </AnchorLink>
            </li>
         ))}
         <li>
            <Button
               size="small"
               isDark={false}
               isRounded={true}
               isExternalLink={false}
               href="mailto:iampaolotodde@gmail.com"
               label="Get in touch"
               iconLeft={<IconEnvelope />}
            />
         </li>
      </ul>
   )
}

const MobileNavItems = ({ navItems, onClick }) => (
   <div className="md:hidden space-top-header h-screen bg-white/90 backdrop-blur-md">
      <ul className="flex flex-col space-y-4 text-center space-top-lg">
         {navItems.map((item, i) => (
            <li className="w-[60%] mx-auto" key={i} onClick={onClick}>
               <AnchorLink to={item.to} title={item.text} stripHash>
                  <p className="uppercase text-dark">{item.text}</p>
               </AnchorLink>
            </li>
         ))}
         <li className="mx-auto space-top-xs">
            <Button
               size="regular"
               isDark={true}
               isRounded={true}
               isExternalLink={false}
               href="mailto:iampaolotodde@gmail.com"
               label="Contact"
               iconRight={<IconEnvelope />}
            />
         </li>
      </ul>
   </div>
)

const Navigation = () => {
   const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
   const [scrollPosition, setScrollPosition] = useState(0)

   const handleScroll = () => {
      const position = window.scrollY
      setScrollPosition(position)
   }

   useEffect(() => {
      window.addEventListener("scroll", handleScroll, { passive: true })

      return () => {
         window.removeEventListener("scroll", handleScroll)
      }
   }, [])

   const navItems = [
      { to: "/about", text: "About" },
      { to: "/#work", text: "Work" },
      { to: "/blog", text: "Blog" },
      { to: "/about#side-projects", text: "Creative Projects" },
   ]

   const toggleMobileNav = () => {
      setIsMobileNavOpen((prev) => !prev)
   }

   const closeMobileNav = () => {
      setIsMobileNavOpen(false)
   }

   const menuAnimation = useSpring({
      transform: isMobileNavOpen ? "translateY(0%)" : "translateY(-100%)",
      opacity: isMobileNavOpen ? 1 : 0,
      config: { mass: 3, tension: 700, friction: 70 },
   })

   return (
      <header
         className={`fixed z-50 w-full top-0 space-top-xs right-0 left-0  pb-2 ${
            scrollPosition > 150
               ? "backdrop-blur-sm bg-stone-100/70 transform -translate-y-2 lg:-translate-y-5 duration-400 ease-in-out"
               : "bg-opacity-0 transform duration-400 ease-in-out"
         }`}
      >
         <ContainerSite>
            <nav className="w-full flex justify-between items-center">
               <Link className="group w-[18%]" to="/" title="Home">
                  <IconComponent />
               </Link>
               <NavItems navItems={navItems} />
               <button className="md:hidden focus:outline-none" onClick={toggleMobileNav}>
                  {isMobileNavOpen ? <IconClose className="w-8 h-8" /> : <IconMenu className="w-8 h-8" />}
               </button>

               {isMobileNavOpen && (
                  <animated.div
                     className="absolute -z-10 top-0 left-0 right-0"
                     style={menuAnimation}
                     onClick={closeMobileNav}
                  >
                     <MobileNavItems navItems={navItems} onClick={closeMobileNav} />
                  </animated.div>
               )}
            </nav>
         </ContainerSite>
      </header>
   )
}

export default Navigation
