import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import ContainerSite from "../3_layouts/ContainerSite"
import ScrollToTopButton from "../1_components/ScrollToTopButton"
import IconCopyright from "../svg/icons/copyright.svg"
import IconLinkedin from "../svg/icons/linkedin.svg"
import IconInstagram from "../svg/icons/instagram.svg"
import IconMail from "../svg/icons/mail.svg"

const footerLinks = [
   {
      label: "Case studies",
      to: "/#work",
      aria: "Link to work section",
   },
   {
      label: "About",
      to: "/about",
      aria: "Link to about section",
   },
   {
      label: "Recommendations",
      to: "/about#recommendations",
      aria: "Link to recommendations section",
   },
   {
      label: "Contact",
      to: "/about#contact",
      aria: "Link to contact section",
   },
]

const socialIcons = [
   {
      href: "https://www.linkedin.com/in/paolo-todde/",
      icon: <IconLinkedin alt="LinkedIn icon" className="w-6" />,
      alt: "LinkedIn icon",
      label: "LinkedIn icon",
   },
   {
      href: "https://www.instagram.com/paolo__todde/",
      icon: <IconInstagram alt="Instagram icon" className="w-6" />,
      alt: "Instagram icon",
      label: "Instagram icon",
   },
   {
      href: "mailto:room01info@gmail.com",
      icon: <IconMail alt="Send me an email" className="w-6" />,
      alt: "Email icon",
      label: "Email icon",
   },
]

const renderFooterLinks = () => {
   return (
      <ul className="flex flex-col justify-between space-y-3 items-center md:items-start pl-3">
         {footerLinks.map((footerLink, index) => (
            <li key={index} aria-label={footerLink.aria}>
               <AnchorLink to={footerLink.to} title={footerLink.label} key={index}>
                  <p className="body-sm uppercase dark-text-hover hover:underlined">{footerLink.label}</p>
               </AnchorLink>
            </li>
         ))}
      </ul>
   )
}

const renderSocialIcons = () => {
   return (
      <div className="bg-gray-100 p-2 border-radius-md border-2 border-dark">
         <ul className="flex flex-col items-center space-y-5 h-full">
            {socialIcons.map((socialIcon, index) => (
               <li key={index} className="group rounded-full cursor-pointer">
                  <a
                     className="dark-text-hover"
                     title={socialIcon.label}
                     aria-label={socialIcon.label}
                     href={socialIcon.href}
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     {socialIcon.icon}
                  </a>
               </li>
            ))}
         </ul>
      </div>
   )
}

const currentYear = new Date().getFullYear()

const Footer = () => {
   return (
      <footer className="w-full" role="group" aria-label="Footer">
         <ContainerSite>
            <div
               className="flex flex-col md:flex-row justify-between
                          space-y-16 md:space-y-0
                          space-top-xl space-bottom-xl"
            >
               <div className="flex-1 flex flex-col space-y-1 items-center md:items-start lg:p-0">
                  <p>
                     Designed in
                     <a
                        className="text-grey"
                        href="https://www.figma.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Link to Figma"
                     >
                        {" "}
                        Figma
                     </a>
                  </p>
                  <p>
                     Coded with{" "}
                     <a
                        className="text-grey"
                        href="https://www.gatsbyjs.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Link to Gatsby"
                     >
                        Gatsby(React.js)
                     </a>{" "}
                  </p>
                  <p>
                     Styled with{" "}
                     <a
                        className="text-grey"
                        href="https://tailwindcss.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Link to Tailwindcss"
                     >
                        Tailwind CSS
                     </a>{" "}
                  </p>
                  <p className="flex items-center space-x-0 space-top-xxs">
                     <IconCopyright className="w-5 h-full inline-flex md:mr-1" />
                     <span className="body-sm">{currentYear}</span>
                  </p>
               </div>

               <div className="flex-1 flex justify-between flex-col md:flex-row items-center space-y-16 md:space-y-0">
                  {renderSocialIcons()}
                  {renderFooterLinks()}
                  <div className="sm:hidden text-center lg:text-left">
                     <ScrollToTopButton />
                  </div>
               </div>
            </div>
         </ContainerSite>
      </footer>
   )
}

export default Footer
